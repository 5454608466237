//primary color
$primary-basic: #0073C4;
$primary-lighter: #E8F5FF;
$primary-light: #45A4E6;
$primary-dark: #005E9E;
$primary-white: #FFFFFF;

//error color
$err-basic: #F45B19;
$err-lighter: #FCEAE2;
$err-light: #F8966C;
$err-dark: #C44914;

//warning color
$warn-basic: #F4AC1C;
$warn-lighter: #FCF3D7;
$warn-light: #F8CA6D;
$warn-dark: #DA9100;

//success color
$scs-basic: #389E0D;
$scs-lighter: #F6FFED;
$scs-light: #76C541;
$scs-dark: #237804;

//additional color
$add-basic: #4850DB;
$add-lighter: #E6E7FF;
$add-light: #919AFF;
$add-dark: #2F36B5;

//neutral color
$ink-basic: #172B4D;
$ink-lighter: #6B778C;
$ink-light: #42526E;
$ink-lightest: #AAB2BF;

$sky-basic: #D3D7DE;
$sky-lighter: #F5F6F7;
$sky-light: #E6E8EB;
$sky-lightest: #FAFBFC;