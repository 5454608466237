.survey-online-page {
  h2 {
    text-align: center;
  
    .phone-err {
      color: red;
      font-weight: bold;
    }
  }
  
  .typeform-content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .besins-countdown {
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #42526e;
    text-align: center;
  }
  
  .ant-spin-nested-loading {
    top: 150px;
  
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: rgb(0, 115, 196);
      }
    }
  
    .ant-spin-text {
      color: rgb(0, 115, 196);
    }
  }
}